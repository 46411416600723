jQuery(function() {

  // by default treat language modal as language, not welcome
  jQuery('#languageModal .modal-body').attr('hidden', true);
  jQuery('#languageModal .modal-footer').attr('hidden', false);

  const setCookie = function(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    const expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
  };

  const readCookie = function(cname) {
    const name = cname + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  };

  const cookiePolicyClick = function(accept) {
    if (accept) {
      setCookie('cookiePolicy', 'accept', 99999);
    }
    else {
      setCookie('cookiePolicy', 'close', 99999);
    }
    jQuery('.privacy-policy-warning').attr('hidden', true);
  };

  jQuery('.privacy-policy-warning .agree').click(function() {
    cookiePolicyClick(true);
  });

  jQuery('.privacy-policy-warning .close').click(function() {
    cookiePolicyClick(false);
  });

  if (readCookie('cookiePolicy')) {
    jQuery('.privacy-policy-warning').attr('hidden', true);
  }
  else {
    jQuery('.privacy-policy-warning').attr('hidden', false);
  }
  
  /*
  if (!readCookie('welcomeMessage') && readCookie('klaro')) {
    jQuery('#languageModal .modal-body').attr('hidden', false);
    jQuery('#languageModal .modal-footer').attr('hidden', true);
    //jQuery('#languageModal, .modal-mask').addClass('show');
    //jQuery('#languageModal, .modal-mask').attr('aria-hidden', false);
    //jQuery('.modal-mask').click(function(e) {
      //e.preventDefault();
    //});
    jQuery('#languageLink').on('click', function(e){
      $('body').modaal('close');
      e.preventDefault();
    });
    jQuery('body').modaal({ 
      content_source: '#languageModal',
      start_open: true,
      after_close: function(){
        setCookie('welcomeMessage', 'shown', 99999);
        window.location.reload();
      }
    });
  }
  else {
    jQuery('#languageModal .modal-body').attr('hidden', true);
    jQuery('#languageModal .modal-footer').attr('hidden', false);
  }
  */
});
