(function ($, talonUtil) {

    // Set copyright date
    const d = new Date();
    const y = d.getFullYear();
    jQuery('#cop_date').text(y);

    $(".main-nav").clickMenu({
        expanders: true
    });

    /** Stop referrer Phishing hack + ADA */
    $("a[target=_blank], a[target=new]").each(function () {
        $(this)
            .attr("rel", "noopener noreferrer")
            .append("<span class='sr-only'>(Opens in a new window)</span>");
    });

   /** Cross browser SVG loading support **/
   svg4everybody();

    talonUtil.setupToggles();
    talonUtil.setupScrollPointerBlocker();
    talonUtil.setupUserBinds();
})(jQuery, window.talonUtil);
