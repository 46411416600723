'use strict';

/* Scale a .watermark element to fit within its parent (.watermark-container). This function will also rotate the watermark to fit vertically when the container is portrait oriented.
*/
const waterMarkScale = function() {
  jQuery('.watermark').each(function(wm) {
    const p = jQuery(this).parent();
    const pW = jQuery(p).width();
    const pH = jQuery(p).height();
    const css = { position: 'absolute', top: '50%', left: '50%'};
    let w = 0;
    let h = 0;
    let t = 'translate(-50%, -50%)';
    jQuery(this).css('transform', 'none');
    if (pW >= pH) {
      w = jQuery(this).width();
      h = jQuery(this).height();
    }
    else {
      // flip height / width and turn  90 degrees when the parent is taller than it is wide.
      h = jQuery(this).width();
      w = jQuery(this).height();
      t += ' rotate(90deg)';
    }
    const sY = pH / h;
    const sX = pW / w;
    // scale to the smallest difference
    const s = Math.min(sY, sX).toFixed(2);
    t += ' scale(' + s + ')';
    css.transform = t;
    jQuery(this).css(css);
  });
};
waterMarkScale();
jQuery(window).resize(function() {
  waterMarkScale();
});
