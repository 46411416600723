// https://github.com/fregante/object-fit-images


jQuery(function() {
  objectFitPolyfill();
  const PolyFillImages = document.querySelectorAll('.object-fit-cover, .figure-16-9 img');
  objectFitPolyfill(PolyFillImages);
  let resizeTimer = null;
  jQuery(window).on('resize', function() {

    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function() {
    // Run code here, resizing has "stopped"
      objectFitPolyfill(PolyFillImages);
    }, 250);
  });
});
