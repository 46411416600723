jQuery('[data-toggle="modal"]').click(function(e) {
  const target = jQuery(this).attr('data-target');
  const $modal = jQuery(target);
  const $mask = jQuery('.modal-mask[data-target="' + target + '"]');

  /* Legacy
  const hidden = $modal.attr('aria-hidden') === true;
  $modal.toggleClass('show');
  $mask.toggleClass('show');
  $modal.attr('aria-hidden', !hidden);
  $mask.attr('aria-hidden', !hidden);
  const $buttons = jQuery('[data-toggle="modal"][data-target="' + target + '"]');
  $buttons.toggleClass('active');
  */
  
 jQuery(this).modaal({ 
   content_source: target,
   start_open: true 
 });
 e.preventDefault();
});

jQuery('.modaal-video-container .cookie-settings').on('click', function(){
  console.log('taco2');
  $('[data-toggle="modal"]').modaal('close');
});

jQuery('.video-modal').modaal({
  type: 'video'
});

